import React, { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useChoreographerAssets } from '@confluence/choreographer-services';
import { fg } from '@confluence/feature-gating';

import { useLiveDocChangeboarding } from './hooks/useLiveDocChangeboarding';

const LIVE_DOC_MODES_TOGGLE_SPOTLIGHT_ID = 'live-doc-modes-toggle-spotlight';
const LIVE_DOC_SHARE_BUTTON_SPOTLIGHT_ID = 'live-doc-share-button-spotlight';

export const LIVE_DOC_MODES_TOGGLE_TARGET = 'live-doc-modes-toggle-spotlight-target';
export const LIVE_DOC_SHARE_BUTTON_TARGET = 'live-doc-share-button-spotlight-target';

const ACTION_SUBJECT_IDS = ['liveDocTourEditToggle', 'liveDocTourShareMenu'];

enum MessageType {
	Engagement = 'engagement',
	Transactional = 'transactional',
}

export const LiveDocSpotlightsTourComponent = () => {
	const [activeSpotlight, setActiveSpotlight] = useState<null | number>(null);
	const [messageType, setMessageType] = useState<MessageType>(MessageType.Engagement);

	const { Spotlight } = useChoreographerAssets();
	const { formatMessage } = useIntl();
	const {
		liveDocTour: { complete },
	} = useLiveDocChangeboarding();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const start = () => setActiveSpotlight(0);
	const next = () => {
		setMessageType(MessageType.Transactional);
		setActiveSpotlight((activeSpotlight || 0) + 1);
	};
	const back = () => setActiveSpotlight((activeSpotlight || 1) - 1);
	const end = () => {
		setActiveSpotlight(null);
		complete();
	};

	useEffect(() => {
		start();
	}, []);

	useEffect(() => {
		if (activeSpotlight !== null && messageType === MessageType.Transactional) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'shown',
					actionSubject: 'spotlight',
					actionSubjectId: ACTION_SUBJECT_IDS[activeSpotlight],
					source: 'liveDocTour',
				},
			}).fire();
		}
	}, [createAnalyticsEvent, messageType, activeSpotlight]);

	const spotlights = [
		<Spotlight
			actions={[
				{
					onClick: () => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'button',
								actionSubjectId: 'next',
								source: 'liveDocTourEditToggleSpotlight',
							},
						}).fire();
						next();
					},

					text: formatMessage(editorSpotlight.next),
				},
				{
					onClick: () => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'button',
								actionSubjectId: 'dismiss',
								source: 'liveDocTourEditToggleSpotlight',
							},
						}).fire();
						end();
					},
					text: formatMessage(editorSpotlight.dismiss),
				},
			]}
			dialogPlacement={fg('confluence_frontend_object_header') ? 'bottom right' : 'bottom left'}
			heading={formatMessage(editorSpotlight.heading)}
			target={LIVE_DOC_MODES_TOGGLE_TARGET}
			messageId={LIVE_DOC_MODES_TOGGLE_SPOTLIGHT_ID}
			messageType={messageType}
			key={LIVE_DOC_MODES_TOGGLE_SPOTLIGHT_ID}
			targetRadius={4}
			shouldWatchTarget
			onMessageDisposition={(disposition) => {
				if (disposition === 'started') {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							action: 'shown',
							actionSubject: 'spotlight',
							actionSubjectId: ACTION_SUBJECT_IDS[0],
							source: 'liveDocTour',
						},
					}).fire();
				}
			}}
			targetBgColor={token('elevation.surface')}
		>
			{formatMessage(editorSpotlight.body)}
		</Spotlight>,

		<Spotlight
			actions={[
				{
					onClick: () => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'button',
								actionSubjectId: 'ok',
								source: 'liveDocTourShareMenuSpotlight',
							},
						}).fire();
						end();
					},
					text: formatMessage(collabSpotlight.ok),
				},
				{
					onClick: () => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'button',
								actionSubjectId: 'back',
								source: 'liveDocTourShareMenuSpotlight',
							},
						}).fire();
						back();
					},
					text: formatMessage(collabSpotlight.back),
				},
			]}
			dialogPlacement="bottom right"
			heading={formatMessage(collabSpotlight.heading)}
			target={LIVE_DOC_SHARE_BUTTON_TARGET}
			messageId={LIVE_DOC_SHARE_BUTTON_SPOTLIGHT_ID}
			messageType="transactional"
			key={LIVE_DOC_SHARE_BUTTON_SPOTLIGHT_ID}
			targetRadius={4}
			targetBgColor={token('elevation.surface')}
		>
			{formatMessage(collabSpotlight.body)}
		</Spotlight>,
	];

	return (
		<SpotlightTransition>
			{activeSpotlight === null ? null : spotlights[activeSpotlight]}
		</SpotlightTransition>
	);
};

const editorSpotlight = defineMessages({
	heading: {
		id: 'onboarding-live-doc-changeboarding.editorSpotlight.heading',
		defaultMessage: 'Launch into work with a live doc',
		description: 'Title of a tutorial message describing the Live Docs editor.',
	},
	body: {
		id: 'onboarding-live-doc-changeboarding.editorSpotlight.body',
		defaultMessage: 'Make instant edits without having to publish.',
		description: 'Body of a tutorial message describing the Live Docs editor.',
	},
	dismiss: {
		id: 'onboarding-live-doc-changeboarding.editorSpotlight.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Label for a button to dismiss the onboarding tutorial',
	},
	next: {
		id: 'onboarding-live-doc-changeboarding.editorSpotlight.next',
		defaultMessage: 'Next',
		description: 'Label for a button to show the next onboarding spotlight',
	},
	stepOfSteps: {
		id: 'onboarding-live-doc-changeboarding.editorSpotlight.stepOfSteps',
		defaultMessage: '{step}/{steps}',
		description: 'A label that tracks which step of the tour the user is currently on',
	},
});

const collabSpotlight = defineMessages({
	heading: {
		id: 'onboarding-live-doc-changeboarding.collabSpotlight.heading',
		defaultMessage: 'Open for collaboration',
		description: 'Title of a tutorial message describing the Live Docs share menu.',
	},
	body: {
		id: 'onboarding-live-doc-changeboarding.collabSpotlight.body',
		defaultMessage: 'Anyone can view and edit this live doc. Make it private anytime.',
		description: 'Body of a tutorial message describing the Live Docs share menu.',
	},
	back: {
		id: 'onboarding-live-doc-changeboarding.collabSpotlight.back',
		defaultMessage: 'Back',
		description: 'Label for a button to show the previous onboarding spotlight',
	},
	ok: {
		id: 'onboarding-live-doc-changeboarding.collabSpotlight.ok',
		defaultMessage: 'Ok',
		description: 'Label for button to complete the onboarding tutorial.',
	},
	gotIt: {
		id: 'onboarding-live-doc-changeboarding.collabSpotlight.got-it',
		defaultMessage: 'Got it',
		description: 'Button text indicating the user is going to dismiss the onboarding tutorial',
	},
	stepOfSteps: {
		id: 'onboarding-live-doc-changeboarding.collabSpotlight.stepOfSteps',
		defaultMessage: '{step}/{steps}',
		description: 'A label that tracks which step of the tour the user is currently on',
	},
});
